import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"

// Connects to data-controller="sortable-form"
export default class extends Controller {
  static targets = ["form", "sortable", "position"];

  connect() {
    this.initSortable();
  }

  initSortable() {
    new Sortable(this.sortableTarget, {
      animation: 150,
      handle: '.handle', // Assuming you're using '.handle' class for drag handle
      onEnd: this.onSortEnd.bind(this),
    });
  }

  onSortEnd(event) {
    this.formTarget.requestSubmit();
  }
}
