import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "input", "button", "radio" ]

  connect() {
    // Toggle checkout needs to run after the form has stabilized. Otherwise it
    // will run before the form has been fully loaded and will disable the button
    setTimeout(() => {
      this.toggle();
    }, 0);
  }

  // This handles both the 'Cart' and 'Checkout' enable/disable buttons.
  toggle() {
    let inputResult = this.inputTargets.some((input) => {
      return this.isVisible(input) && (!input.disabled && (input.value == "" || input.selectedIndex == 0));
    });

    let radioResult = false;
    if (this.hasRadioTarget) {
      let radioGroups = {};
      this.radioTargets.forEach((radio) => {
        (radioGroups[radio.name] = radioGroups[radio.name] || []).push(radio);
      });
      radioResult = Object.values(radioGroups).some(radioTargets => {
        return !radioTargets.some(radio => radio.checked);
      });
    }
    this.buttonTarget.disabled = inputResult || radioResult;
  }


  // private

  isVisible(elem) {
    return !!(elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length);
  }
}
