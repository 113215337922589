import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="gallery-update"
export default class extends Controller {
  static targets = [ "gallery" ]

  change(event) {
    const url = new URL(this.galleryTarget.src)
    url.searchParams.set('color', event.target.value)

    this.galleryTarget.src = url.toString()
  }
}
