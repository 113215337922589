import { Controller } from "@hotwired/stimulus"


// This controller can be removed once https://github.com/hotwired/turbo/issues/598 is fixed
// Connects to data-controller="anchor-link"
export default class extends Controller {
  static targets = ["destination"];

  jump(event) {
    this.destinationTargets.find((target) => target.id === event.params.id).scrollIntoView();
    event.preventDefault();
  }
}
