import { Controller } from "@hotwired/stimulus"
import {DataTable} from "simple-datatables";

// Connects to data-controller="data-table"
export default class extends Controller {
  connect() {
    this.dataTable = new DataTable(this.element, {
      paging: false,
      searchable: false,
      classes: {
        search: "w-100",
        input: "form-control"
      }
    });
  }
}
