import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="personalization-warning"
export default class extends Controller {
  static targets = [ "personalizations" ]

  warn(event) {
    let personalizationsSelected = this.personalizationsTargets.some((personalization) => {
      return personalization.checked;
    });

    if (!personalizationsSelected && this.personalizationsTargets.length > 0) {
      if (confirm('Are you sure you do not want to personalize any of your items?')) {
        return true
      } else {
        event.preventDefault();
        event.stopImmediatePropagation();
        return false
      }
    }
  }
}
