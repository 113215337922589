import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="case-consideration"
export default class extends Controller {
  static targets = [ "toggle", "input" ]

  connect() {
    this.updateTargets();
  }

  toggle() {
    this.toggleTarget.classList.toggle("active");
    this.updateTargets();
  }

  updateTargets() {
    if(this.toggleTarget.classList.contains("active")) {
      this.inputTarget.classList.add("text-uppercase");
    } else {
      this.inputTarget.classList.remove("text-uppercase");
    }
  }
}
