import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="search"
export default class extends Controller {
  static targets = ["row"];

  filter(event) {
    const searchText = event.target.value.toLowerCase();

    this.rowTargets.forEach((row) => {
      const isMatch = Array.from(row.cells).some(cell =>
        cell.textContent.toLowerCase().includes(searchText));
      row.style.display = isMatch ? "" : "none";
    });
  }
}
