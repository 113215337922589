import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="parent-click"
export default class extends Controller {
  static targets = [ 'link' ]

  click(e) {
    // Make sure the target element isn't a link or a button
    if (!e.target.hasAttribute('href')) {
      this.linkTarget.click()
    }
  }
}
