import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="upcase-fields"
export default class extends Controller {
  static targets = ["input"]

  upcase() {
    this.inputTargets.forEach(input => {
      if (input.classList.contains('text-uppercase')) {
        input.value = input.value.toUpperCase();
      }
    });
  }
}
