import { Controller } from "@hotwired/stimulus"
import { Carousel } from "bootstrap"

export default class extends Controller {
  static targets = ["carousel"]

  connect() {
    this.carouselInstance = Carousel.getOrCreateInstance(this.carouselTarget)
  }

  changeImage(event) {
    this.carouselInstance.to(event.currentTarget.dataset.index)
  }
}
