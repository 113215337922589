import { Controller } from "@hotwired/stimulus"

const moneyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
});

// Connects to data-controller="subtotal"
export default class extends Controller {
  static targets = [ "personalizations", "subtotal" ]
  static values = { itemsTotal: Number }

  connect() {
    this.update();
  }

  update() {
    let subtotal = this.itemsTotalValue;

    this.personalizationsTargets.forEach((personalization) => {
      if (personalization.checked) {
        subtotal += Number(personalization.dataset.priceCents.replace(/_/g, ""));
      }
    });

    this.subtotalTarget.innerHTML = moneyFormatter.format(subtotal / 100);
  }
}
