import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="toggle-personalization"
export default class extends Controller {
  static targets = [ "checkbox", "input", "button" ]

  // This executes after the form-state controller restores any locally stored form values.
  connect() {
    this.toggle();
  }

  toggle() {
    if (!this.hasCheckboxTarget) return;

    if (this.checkboxTarget.checked) {
      this.inputTarget.disabled = false;
      this.buttonTarget.disabled = false;
    } else {
      this.inputTarget.disabled = true;
      this.buttonTarget.disabled = true;
    }
  }
}
