import {Controller} from "@hotwired/stimulus";

// Connects to data-controller="form-state"
export default class extends Controller {
  static values = {groupId: String}
  static targets = ["input", "radio", "select", "checkbox", "toggle"]

  connect() {
    this.restoreState();
  }

  getStoredData() {
    const savedState = localStorage.getItem(this.storageKey());
    if (savedState) {
      return JSON.parse(savedState);
    } else {
      return {
        inputs: {},
        radios: {},
        selects: {},
        checkboxes: {},
        inactiveToggles: []
      };
    }
  }

  saveState() {
    const data = this.getStoredData();

    // Save the state of all inputs
    this.inputTargets.forEach(input => {
      data.inputs[input.id] = input.value;
    });

    // Save the state of all radio buttons
    this.radioTargets.forEach(radio => {
      if (radio.checked) {
        data.radios[radio.id] = radio.value;
      }
    });

    // Save the state of all select elements
    this.selectTargets.forEach(select => {
      data.selects[select.id] = select.value;
    });

    // Save the state of all checkboxes
    this.checkboxTargets.forEach(checkbox => {
      data.checkboxes[checkbox.id] = checkbox.checked;
    });

    // Save the state of inactive toggles
    this.toggleTargets.forEach(toggle => {
      const index = data.inactiveToggles.indexOf(toggle.id);
      if (!toggle.classList.contains("active")) {
        if (index === -1) {
          data.inactiveToggles.push(toggle.id);
        }
      } else {
        if (index !== -1) {
          data.inactiveToggles.splice(index, 1);
        }
      }
    });

    localStorage.setItem(this.storageKey(), JSON.stringify(data));
  }

  restoreState() {
    const data = this.getStoredData();

    // Restore the state of all inputs
    this.inputTargets.forEach(input => {
      if (data.inputs[input.id] !== undefined) {
        input.value = data.inputs[input.id];
      }
    });

    // Restore the state of all radio buttons
    this.radioTargets.forEach(radio => {
      if (data.radios[radio.id]) {
        radio.checked = true;
      }
    });

    // Restore the state of all select elements
    this.selectTargets.forEach(select => {
      if (data.selects[select.id]) {
        select.value = data.selects[select.id];
      }
    });

    // Restore the state of all checkboxes
    this.checkboxTargets.forEach(checkbox => {
      if (data.checkboxes.hasOwnProperty(checkbox.id)) {
        checkbox.checked = data.checkboxes[checkbox.id];
      }
    });

    // Restore the state of the toggle button
    this.toggleTargets.forEach(toggle => {
      if (data.inactiveToggles.includes(toggle.id)) {
        toggle.classList.remove("active");
      } else {
        toggle.classList.add("active");
      }
    });
  }

  clearState() {
    localStorage.removeItem(this.storageKey());
  }

  storageKey() {
    return `formState-${this.groupIdValue}`;
  }

  changeHandler() {
    this.saveState();
  }
}
