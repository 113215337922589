import { Controller } from "@hotwired/stimulus"
import { Modal } from "bootstrap"

// Connects to data-controller="show-modal"
export default class extends Controller {
  static targets = [ "modal" ]

  connect() {
    this.modalTarget.addEventListener('hidden.bs.modal', this.modalHidden.bind(this));

    new Modal(this.modalTarget).show()
  }

  // Avoid modal re-appearing if the user clicks the browser's back button
  modalHidden() {
    this.modalTarget.remove()
  }
}
