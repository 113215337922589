import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="admin--discount-codes"
export default class extends Controller {
  static targets = [ "selected", "percentage", "fixed" ]

  connect() {
    this.toggleDisplay()
  }

  toggleDisplay() {
    const showPercentage = this.selectedTarget.value == "percentage";
    this.toggleTargets(this.percentageTargets, showPercentage);
    this.toggleTargets(this.fixedTargets, !showPercentage);
  }

  toggleTargets(targets, show) {
    targets.forEach(target => {
      target.hidden = !show;
    });
  }
}
