import { Controller } from "@hotwired/stimulus"
import * as Square from "@square/web-sdk"

// Connects to data-controller="square-payments"
export default class extends Controller {
  static targets = [ "card", "error", "submitButton", "overlay", "recaptchaV3", "recaptchaV2" ]
  static values = {
    applicationId: String,
    locationId: String,
    submitPaymentUrl: String,
    orderId: String
  }

  async connect() {
    this.squarePaymentsClient = await Square.payments(this.applicationIdValue, this.locationIdValue);
    this.card = await this.squarePaymentsClient.card();
    this.refreshIdempotencyKey();
    await this.card.attach(this.cardTarget);
  }

  async submit(event) {
    this.submitButtonTarget.disabled = true
    this.overlayTarget.classList.remove('d-none')
    event.preventDefault()
    const result = await this.card.tokenize()
    if (result.status === 'OK') {
      this.submitPayment(result.token)
    } else {
      this.handleErrorResponse(result)
    }
  }

  async submitPayment(token) {
    const submitPaymentResponse = await fetch(this.submitPaymentUrlValue, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]')?.getAttribute('content')
      },
      body: JSON.stringify({
        "cart": {
          "token": token,
          "order_id": this.orderIdValue,
          "idempotency_key": this.idempotencyKey
        },
        "g-recaptcha-v3-response": this.recaptchaV3Target?.querySelector('#g-recaptcha-response-data-submit')?.value,
        "g-recaptcha-v2-response": this.recaptchaV2Target?.querySelector('.g-recaptcha-response')?.value
      })
    })
    if (submitPaymentResponse.ok) {
      const response = await submitPaymentResponse.json()

      window.location.href = response.redirect_path
    }
    else {
      this.handleErrorResponse(submitPaymentResponse)
    }
  }

  async handleErrorResponse(response) {
    const genericError = "An error occurred while processing your payment. Please validate all fields and try again. If the problem persists, please contact support.";

    if (typeof grecaptcha !== 'undefined') {
      // Reset both v3 and v2 reCAPTCHA. Note that Resetting v3 captcha doesn't actually do anything, however the ordering is inconsistent and therefore both have to be reset.
      grecaptcha.reset();
      grecaptcha.reset(1);

      this.recaptchaV2Target.classList.remove('d-none');
    }

    try {
      // Try to extract the error message from the response body
      const errorData = await response.json();
      this.errorTarget.textContent = errorData.error || genericError;
    } catch (e) {
      // If the response is not JSON or another error occurs
      this.errorTarget.textContent = genericError;
    }

    this.errorTarget.classList.remove('d-none');
    this.refreshIdempotencyKey();
    this.submitButtonTarget.disabled = false;
    this.overlayTarget.classList.add('d-none');
  }

  refreshIdempotencyKey() {
    this.idempotencyKey = self.crypto.randomUUID();
  }
}
