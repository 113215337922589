import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs";

// Connects to data-controller="manage-gallery"
export default class extends Controller {
  connect() {
    this.initializeSortable();
  }

  initializeSortable() {
    this.sortable = new Sortable(this.element, {
      group: 'gallery',
      handle: '.panel',
      animation: 150,
      onEnd: (evt) => {
        const items = this.element.querySelectorAll('.sortable-item');
        items.forEach((item, index) => {
          item.querySelector('.gallery-order').value = index;
        });
      }
    });

    // Adjust cursor style on mouse down and up
    this.setupCursorHandlers();
  }

  removeImageFromGallery(event) {
    event.preventDefault();

    // Assuming the button has a data-action that calls this method
    const imageId = event.currentTarget.dataset.imageId;

    // Hide the image panel
    const imagePanel = document.getElementById('image-panel-' + imageId);
    if (imagePanel) {
      imagePanel.style.display = 'none';
    }

    // Set the destroy value
    const destroyInput = document.getElementById('product-gallery-destroy-' + imageId);
    if (destroyInput) {
      destroyInput.value = 'true';
    }
  }

  // private

  setupCursorHandlers() {
    const panels = this.element.querySelectorAll('.panel');
    panels.forEach(panel => {
      panel.addEventListener('mousedown', () => {
        panel.style.cursor = 'move';
      });
      panel.addEventListener('mouseup', () => {
        panel.style.cursor = 'auto';
      });
    });
  }
}
