import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="show-shipping"
export default class extends Controller {
  static targets = [ "radio", "questions", "fields" ]

  connect() {
    this.toggle();
  }

  toggle() {
    if (this.hasRadioTarget) {
      if (this.radioTarget.checked) {
        this.questionsTarget.classList.remove("d-none");

        this.fieldsTargets.forEach((field) => {
          field.required = true;
        });
      } else {
        this.questionsTarget.classList.add("d-none");

        this.fieldsTargets.forEach((field) => {
          field.required = false;
        });
      }
      // Allow other controllers to react to this event
      this.dispatch("toggle")
    }
  }
}
